import { Alert, Button, Modal, Select, Stack, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { any, map } from 'lodash/fp';
import React from 'react';

import { OrganizationType, useAssignDevices } from '@portals/api/partners';
import { ModalBody, ModalFooter } from '@portals/core';
import { ModalProps } from '@portals/framework';
import { ReactComponent as Danger } from '@portals/icons/linear/danger.svg';
import { DeviceType } from '@portals/types';

export interface ClaimDeviceProps
  extends ModalProps<{
    devices: Array<DeviceType>;
    organizations: OrganizationType[] | undefined;
  }> {}

export function ClaimDevice({
  data: { devices, organizations },
  closeMe,
}: ClaimDeviceProps) {
  const assignDevices = useAssignDevices();

  const form = useForm({
    initialValues: {
      organization_id: '',
    },
  });

  const hasInactiveDeviceModel = any({ device_model_active: false }, devices);

  const organizationsOptions = map((org) => {
    const isDisabled = !org.lab && hasInactiveDeviceModel;

    return {
      label: org.name || '',
      value: org.id,
      disabled: isDisabled,
    };
  }, organizations);

  const onSubmit = (values: typeof form.values) => {
    assignDevices.mutate(
      {
        device_ids: devices.map((device) => device.id),
        organization_id: values.organization_id,
      },
      {
        onSuccess: () => closeMe(),
      }
    );
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      title={
        devices.length === 1
          ? `Assign "${devices[0].model}"`
          : 'Assign multiple devices'
      }
    >
      <Stack>
        <Text color="gray.7" size="sm">
          Assign {devices.length === 1 ? 'device' : 'devices'} to a customer's
          organization
        </Text>

        {hasInactiveDeviceModel ? (
          <Alert icon={<Danger />} color="red">
            Some of the selected device models are unpublished. These devices
            cannot be claimed by non-lab organizations.
          </Alert>
        ) : null}

        <form onSubmit={form.onSubmit(onSubmit)}>
          <ModalBody>
            <Select
              required
              searchable
              withinPortal
              label="Organization"
              data-testid="select-organization-drop-down"
              placeholder="Select Organization"
              data={organizationsOptions}
              {...form.getInputProps('organization_id')}
            />
          </ModalBody>

          <ModalFooter position="right">
            <Button variant="default" onClick={closeMe}>
              Cancel
            </Button>

            <Button
              data-testid="actions-submit-button"
              type="submit"
              loading={assignDevices.isLoading}
            >
              Assign
            </Button>
          </ModalFooter>
        </form>
      </Stack>
    </Modal>
  );
}
