import { FeatureNotificationEnum } from '@portals/api/ui';
import { lazyNamedImport } from '@portals/utils';

export const FEATURES_MAP = {
  [FeatureNotificationEnum.NewSystemIntegratorsExperience]: lazyNamedImport(
    () =>
      import(
        './features-modals/new-system-integrators-experience/NewSystemIntegratorsExperience'
      ),
    'NewSystemIntegratorsExperience'
  ),
} as const;
