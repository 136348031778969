import { createStyles, Modal, useMantineTheme } from '@mantine/core';
import { filter, includes } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';

import {
  useNewCreateDeviceModelWidget,
  useWidgetColors,
} from '@portals/api/partners';
import { MantineThemeProvider } from '@portals/core';
import {
  CommonWidgetFormType,
  WIDGET_COLORS,
  WidgetColorType,
} from '@portals/device-widgets';
import { ModalProps } from '@portals/framework';

import { useUpdateCustomColors } from './device-widgets.hooks';
import { DeviceWidgetMetaType, WidgetsTabType } from './device-widgets.types';
import { ALL_WIDGETS_META } from './devices-widgets.constants';
import { WidgetsList } from './WidgetsList';

export interface AddDeviceWidgetModalProps
  extends ModalProps<{ modelId: string }> {}

export function AddDeviceWidgetModal({
  closeMe,
  data,
}: AddDeviceWidgetModalProps) {
  const { modelId } = data;
  const { classes } = useStyles();
  const updateCustomColors = useUpdateCustomColors();
  const [selectedTab, setSelectedTab] = useState<WidgetsTabType>('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedWidget, setSelectedWidget] =
    useState<DeviceWidgetMetaType | null>(null);

  const theme = useMantineTheme();
  const colors = useWidgetColors();

  const widgetColors: WidgetColorType[] = WIDGET_COLORS.concat(
    Object.keys(colors) as WidgetColorType[]
  );

  const createDeviceModelWidget = useNewCreateDeviceModelWidget(modelId);

  const filteredWidgets = useMemo(() => {
    const normalizedSearchTerm = searchTerm.toLowerCase();

    const allWidgets = ALL_WIDGETS_META;

    const filteredBySearchTerm = normalizedSearchTerm
      ? filter(
          (widgetMeta) =>
            includes(normalizedSearchTerm, widgetMeta.name.toLowerCase()),
          allWidgets
        )
      : allWidgets;

    if (selectedTab === 'all') {
      return filteredBySearchTerm;
    } else if (selectedTab === 'monitoring') {
      return filter({ type: 'monitoring' }, filteredBySearchTerm);
    } else if (selectedTab === 'commands') {
      return filter({ type: 'commands' }, filteredBySearchTerm);
    } else if (selectedTab === 'UI') {
      return filter({ type: 'UI' }, filteredBySearchTerm);
    }
  }, [searchTerm, selectedTab]);

  const onClose = useCallback(() => {
    setSelectedWidget(null);

    closeMe();
  }, [closeMe]);

  const onSubmit = useCallback(
    async (values: CommonWidgetFormType) => {
      if (!selectedWidget) return;

      try {
        await createDeviceModelWidget.mutateAsync({
          name: values.name,
          config: {
            id: selectedWidget.id,
            width: selectedWidget.width,
            height: selectedWidget.height,
            fields: values,
          },
        });

        closeMe();
      } catch (error) {
        console.error(error);
      }
    },
    [closeMe, createDeviceModelWidget, selectedWidget]
  );

  const content = useMemo(() => {
    if (selectedWidget) {
      const WidgetFormRenderer = selectedWidget.widgetFormRenderer;

      return WidgetFormRenderer ? (
        <WidgetFormRenderer
          onSubmit={onSubmit}
          onClose={closeMe}
          onCancel={() => setSelectedWidget(null)}
          modelId={modelId}
          colors={widgetColors}
          onAddCustomColor={updateCustomColors}
        />
      ) : null;
    }

    return (
      <WidgetsList
        setSelectedWidget={setSelectedWidget}
        widgets={filteredWidgets}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        onClose={closeMe}
      />
    );
  }, [
    updateCustomColors,
    widgetColors,
    modelId,
    closeMe,
    filteredWidgets,
    onSubmit,
    searchTerm,
    selectedTab,
    selectedWidget,
  ]);

  return (
    <MantineThemeProvider
      inherit
      theme={{ colors: { ...theme.colors, ...colors } }}
    >
      <Modal
        opened
        onClose={onClose}
        size="1028px"
        padding={0}
        classNames={{
          content: classes.modalContent,
        }}
        title={null}
        withCloseButton={false}
        closeOnClickOutside={false}
      >
        {content}
      </Modal>
    </MantineThemeProvider>
  );
}

const useStyles = createStyles(() => ({
  modalContent: {
    paddingBottom: '0 !important',
    height: 790,
  },
}));
