import { Box, createStyles } from '@mantine/core';
import React from 'react';

import { Modals } from '@portals/framework';

import { HubsHeader } from './hubs-header/HubsHeader';
import { HubsProvider } from './HubsContext';
import { HubsMainContent } from './HubsMainContent';
import { DashboardRoutesProvider } from '../routes/dashboard/DashboardRoutesProvider';
import { QuickNavigationProvider } from '../routes/dashboard/QuickNavigationProvider';

export function HubsLayout() {
  const { classes, cx } = useStyles();

  return (
    <DashboardRoutesProvider>
      <QuickNavigationProvider>
        <HubsProvider>
          <Box className={cx(classes.wrapper, 'page-wrapper')}>
            <HubsHeader />

            <HubsMainContent />
          </Box>

          <Modals />
        </HubsProvider>
      </QuickNavigationProvider>
    </DashboardRoutesProvider>
  );
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    height: '100dvh',
    overflow: 'hidden',
    display: 'grid',
    gridTemplateRows: `${theme.other.navbarHeight}px 1fr`,
  },
}));
